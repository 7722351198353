import React, { useEffect, useState, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Slider from 'rc-slider';
import { calculateExtraCreditsPrice } from '../../../../../../services/utils/calculatePrice';
import 'rc-slider/assets/index.css';
import CurrencyDisplay from '../../../../../../components/CurrencyDisplay/CurrencyDisplay';
import { CREDIT_COLORS } from '../../../../../../constants';
import { toast } from 'react-toastify';
import './AddMoreCreditsBis.css';

export default function AddMoreCredits({
  onAddCredits,
  creditsToAdd,
  subscriptionType,
  organizationCreditPlan,
  user,
  organizationCredits,
  selectedPlan,
  isNoticePeriod,
  creditsIndex,
}: any) {
  const intl = useIntl();
  const sliderRefs = useRef([]);

  const [initialCredits, setInitialCredits] = useState({});

  useEffect(() => {
    setInitialCredits({
      phoneCredits: organizationCredits['phoneCredits'].extra,
      personalEmailCredits: organizationCredits['personalEmailCredits'].extra,
      exportCredits: organizationCredits['exportCredits'].extra,
    });
  }, []);

  const creditsTypes = [
    { id: 'phoneCredits', labelId: 'billing.phoneCredits' },
    { id: 'personalEmailCredits', labelId: 'billing.directEmailCredits' },
    { id: 'exportCredits', labelId: 'billing.exportCredits' },
  ];

  const formatAmount = (amount: any) => {
    if (amount >= 1000000) {
      // Check if the amount is superior to 1 million
      const formatted = (amount / 1000000).toFixed(1);
      return formatted.endsWith('.0') ? `${parseInt(formatted)}M` : `${formatted}M`;
    } else if (amount >= 1000) {
      // Check if the amount is superior to 1000
      const formatted = (amount / 1000).toFixed(1);
      return formatted.endsWith('.0') ? `${parseInt(formatted)}K` : `${formatted}K`;
    }
    return amount.toString();
  };

  useEffect(() => {
    sliderRefs.current.forEach((sliderRef, index) => {
      if (sliderRef) {
        const dots = sliderRef.querySelectorAll('.rc-slider-dot');
        const creditType = creditsTypes[index].id;
        const { extraCredits } = calculateExtraCreditsPrice({
          creditType,
          isAnnual: subscriptionType === 'yearly',
        });

        dots.forEach((element: any, i: any) => {
          if (i > 0) {
            const span = document.createElement('span');
            span.innerHTML = formatAmount(parseInt(extraCredits[i].amount));
            span.classList.add('inject-amount');
            element.appendChild(span);
          }
        });
      }
    });

    return () => {
      sliderRefs.current.forEach((sliderRef) => {
        if (sliderRef) {
          const injectedAmounts = sliderRef.querySelectorAll('.inject-amount');
          injectedAmounts.forEach((span: any) => span.remove());
        }
      });
    };
  }, [subscriptionType, creditsTypes]);

  const findKeyByAmount = (obj: any, targetAmount: any) => {
    return Object.keys(obj).find((key) => obj[key].amount === targetAmount);
  };

  const handleSliderOnChange = (type: any, slideIndex: any) => {
    const { extraCredits } = calculateExtraCreditsPrice({
      creditType: type,
      isAnnual: subscriptionType === 'yearly',
    });

    if (isNoticePeriod) {
      //@ts-expect-error
      const targetAmount = initialCredits[type];
      const key = Object.keys(extraCredits).find(
        (key) => extraCredits[key].amount === targetAmount.toString(),
      );

      if (slideIndex < key) {
        toast.error(
          <FormattedMessage id={'billing.customizePlan.youCantDecreaseAmountOfAddonsTooltip'} />,
        );
        return;
      }
    }

    const updatedCredits = {
      ...creditsToAdd,
      [type]: {
        numberOfCredits: parseInt(extraCredits[slideIndex].amount),
        index: slideIndex,
        price: extraCredits[slideIndex].price,
        unitPrice: calculateExtraCreditsPrice({
          isUnitPrice: true,
          amount: parseInt(extraCredits[slideIndex].amount),
          creditType: type,
          isAnnual: subscriptionType === 'yearly',
        }),
      },
    };

    onAddCredits(updatedCredits);
  };

  function formatPrice(price: any) {
    const priceNumber = Number(price);
    const priceFixed = priceNumber.toFixed(1);
    return priceFixed.endsWith('.0') ? priceFixed.slice(0, -2) : priceFixed;
  }

  return (
    <div id="credits-container" className="credits-section">
      <div className="section-title">
        <h1>
          <FormattedMessage id="billing.moreCredits" />
        </h1>

        <p>
          <FormattedMessage id="billing.customizePlan.useSliderToSelectNb" />
        </p>
      </div>
      {creditsTypes.map(({ id, labelId }, index) => (
        <section className="credit-section" key={id}>
          <div className="custom-credit-type global-fade-in">
            <div className="credit-title">
              <img src={CREDIT_COLORS[id].icon} alt={intl.formatMessage({ id: labelId })} />

              <h5>
                <FormattedMessage id={labelId} />
              </h5>
            </div>

            <div className="pricing-info">
              <div className="info-credit-wrapper">
                <p>
                  <FormattedMessage id="billing.additionalCredits" />
                </p>

                <p className="credits">
                  {creditsToAdd[id].numberOfCredits}

                  <span>
                    {creditsToAdd[id].numberOfCredits === 0 ? (
                      <FormattedMessage id="billing.customizePlan.credit" />
                    ) : (
                      <FormattedMessage id="credits" />
                    )}{' '}
                    /{' '}
                    {subscriptionType === 'yearly' ? (
                      <FormattedMessage id="year" />
                    ) : (
                      <FormattedMessage id="month" />
                    )}
                  </span>
                </p>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'end',
                }}
              >
                <div className="add-credits-right">
                  <CurrencyDisplay
                    user={user}
                    organizationCredits={organizationCredits}
                    amount={formatPrice(creditsToAdd[id].price)}
                  />

                  <span>
                    /
                    {subscriptionType === 'yearly' ? (
                      <FormattedMessage id="year" />
                    ) : (
                      <FormattedMessage id="month" />
                    )}
                  </span>
                </div>

                <div className="price-per-credit">
                  <FormattedMessage id="billing.pricePerCredit" />{' '}
                  <span>
                    <CurrencyDisplay
                      user={user}
                      organizationCredits={organizationCredits}
                      amount={intl.formatNumber((creditsToAdd[id].unitPrice || 0) / 100)}
                    />
                  </span>
                </div>
              </div>
            </div>
            {/* LEFT  */}

            <div className="add-credits-slider" ref={(el) => (sliderRefs.current[index] = el)}>
              <Slider
                min={0}
                max={
                  calculateExtraCreditsPrice({
                    creditType: id,
                    isAnnual: subscriptionType === 'yearly',
                  }).totalItems
                }
                value={creditsToAdd[id].index}
                onChange={(slideIndex) => handleSliderOnChange(id, slideIndex)}
                dots
              />
            </div>
          </div>
        </section>
      ))}
    </div>
  );
}
